import React, { useCallback } from "react";
import tw, { styled } from "twin.macro";
import { useLocation } from "react-router-dom";
import { defaultSteps } from "@utils/navigation";

interface Step {
  label: string;
  path: string;
}

interface StepperProps {
  data?: Step[];
}

interface ActiveStep {
  $active: boolean;
}

const Container = tw.div`px-[5%] py-8`;
const InnerContainer = tw.div`w-full flex justify-between gap-[5px] lg:gap-[15px] max-w-1200 mx-auto`;
const StepContainer = tw.div`flex grow flex-col basis-0`;

const StepLabel = styled.div<ActiveStep>`
  ${tw`flex grow lowercase lg:text-[16px] text-[13px] leading-none`}
  ${({ $active }) => ($active ? tw`text-purple` : tw`text-grey`)}
`;

const ProgressBar = styled.div<ActiveStep>`
  ${tw`flex grow h-[4px] mb-[5px] max-h-[4px] transition-colors duration-1000`}
  ${({ $active }) => ($active ? tw`bg-purple` : tw`bg-grey-300`)}
`;

export const Stepper: React.FC<StepperProps> = ({
  data = defaultSteps,
}: StepperProps) => {
  const location = useLocation();

  const renderSteps = useCallback(
    (data: Step[]) => {
      const activeIndex = data.findIndex(
        (item) => item.path === location.pathname,
      );

      return data.map((item, index) => {
        if (item.path === "/privacy") return null;

        return (
          <StepContainer key={item.path}>
            <ProgressBar $active={index <= activeIndex} />
            <StepLabel $active={index <= activeIndex}>{item.label}</StepLabel>
          </StepContainer>
        );
      });
    },
    [location.pathname],
  );

  return (
    <Container>
      <InnerContainer>{renderSteps(data)}</InnerContainer>
    </Container>
  );
};
