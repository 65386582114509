import { createContext } from "react";

export interface SubscriptionFlowData {
  city?: string;
  country?: string;
  email?: string;
  phone_number?: string;
  promocode: string;
  zipcode: string;
  numberOfLines: number;
  lines: Lines;
  terms: boolean;
  selectedPlan: Plan;
  showHint: boolean;
  paymentData: PaymentData;
  checkout: {
    order_number: string;
  };
}

interface PaymentData {
  discount: number;
  message: string;
  sub_total: number;
  tax: number;
  total: number;
}

interface SubscriptionDataContextType {
  plans: Plan[];
  setPlans: (plans: Plan[]) => void;
  data: SubscriptionFlowData;
  addData: (data: any) => void;
  setGoBack: (fn: () => void) => void;
  navigation: {
    goBack: () => void;
  };
}

export const SUBS_INIT_DATA: SubscriptionFlowData = {
  email: "",
  promocode: "",
  zipcode: "",
  numberOfLines: 0,
  lines: [] as unknown as Lines,
  terms: false,
  selectedPlan: null as unknown as Plan,
  showHint: true,
  checkout: null as any,
  paymentData: null as unknown as PaymentData,
};

const SubscriptionDataContext = createContext<SubscriptionDataContextType>({
  data: SUBS_INIT_DATA,
  setPlans: () => {},
  addData: () => {},
  setGoBack: () => {},
  navigation: {
    goBack: () => null,
  },
  plans: null as unknown as Plan[],
});

export default SubscriptionDataContext;
