import React, { useEffect, useContext, Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import { IntercomProvider } from "react-use-intercom";
import * as Sentry from "@sentry/react";
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import SubscriptionDataProvider from "@context/SubscriptionDataProvider";
import SubscriptionDataContext from "@context/SubscriptionDataContext";
import { sendGAPageEvent, isGA4Available } from "@utils/analytics";
import "react-responsive-modal/styles.css";
import "./index.css";
import { Layout } from "@components/layout/Layout";
import useAidCookie from "hooks/useAidCookie";
import { SpinnerFallback } from "@components/commons/FallbackSpinner";
import { Stepper } from "@components/stepper/Stepper";
import { RedirectToExternal } from "@components/Redirect";

// Lazy load the components
const ErrorEsim = lazy(() => import("@views/ErrorEsim"));
const SuccessPage = lazy(() => import("@views/Success"));
const ErrorPage = lazy(() => import("@views/ErrorPage"));
const BasicInfo = lazy(() => import("@views/BasicInfo"));
const ReallyPrivacy = lazy(() => import("@views/ReallyPrivacy"));
const PurchasePlan = lazy(() => import("@views/PurchasePlan"));

const queryClient = new QueryClient();
const INTERCOM_APP_ID = import.meta.env.VITE_INTERCOM_ID;
const SENTRY_PROJECT_ID = import.meta.env.VITE_SENTRY_PROJECT_ID;
const ENV = import.meta.env.VITE_ENV;

const Root: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const { setGoBack } = useContext(SubscriptionDataContext);
  useAidCookie();

  useEffect(() => {
    if (isGA4Available) {
      sendGAPageEvent(pathname);
    }

    setGoBack(() => {
      navigate(-1);
    });
  }, [pathname]);

  if (pathname === "/") {
    return <RedirectToExternal url="https://really.com/plans" />;
  }

  return <Layout />;
};

const router = createBrowserRouter([
  {
    element: <Root />,
    children: [
      {
        path: "/",
        element: (
          <Suspense fallback={<SpinnerFallback />}>
            <Stepper />
            <Outlet />
          </Suspense>
        ),
        children: [
          {
            path: "basic-info",
            element: (
              <Suspense fallback={<SpinnerFallback />}>
                <BasicInfo />
              </Suspense>
            ),
            errorElement: (
              <Suspense fallback={<SpinnerFallback />}>
                <ErrorPage />
              </Suspense>
            ),
          },
          {
            path: "privacy",
            element: (
              <Suspense fallback={<SpinnerFallback />}>
                <ReallyPrivacy />
              </Suspense>
            ),
            errorElement: (
              <Suspense fallback={<SpinnerFallback />}>
                <ErrorPage />
              </Suspense>
            ),
          },
          {
            path: "purchase-plan",
            element: (
              <Suspense fallback={<SpinnerFallback />}>
                <PurchasePlan />
              </Suspense>
            ),
            errorElement: (
              <Suspense fallback={<SpinnerFallback />}>
                <ErrorPage />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "checkout",
        children: [
          {
            path: "fail",
            element: (
              <Suspense fallback={<SpinnerFallback />}>
                <ErrorEsim />
              </Suspense>
            ),
            errorElement: (
              <Suspense fallback={<SpinnerFallback />}>
                <ErrorPage />
              </Suspense>
            ),
          },
          {
            path: "success",
            element: (
              <Suspense fallback={<SpinnerFallback />}>
                <SuccessPage />
              </Suspense>
            ),
            errorElement: (
              <Suspense fallback={<SpinnerFallback />}>
                <ErrorPage />
              </Suspense>
            ),
          },
        ],
      },
    ],
    errorElement: (
      <Suspense fallback={<SpinnerFallback />}>
        <ErrorPage />
      </Suspense>
    ),
  },
]);

Sentry.init({
  dsn: SENTRY_PROJECT_ID,
  environment: ENV,
  debug: ENV === "local",
  enabled: ENV === "production" || ENV === "staging",
  maxBreadcrumbs: 50,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost"],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.5,
});

ReactDOM.createRoot(document.getElementById("root") as Element).render(
  <React.StrictMode>
    <SubscriptionDataProvider>
      <QueryClientProvider client={queryClient}>
        <IntercomProvider appId={INTERCOM_APP_ID}>
          <RouterProvider router={router} />
        </IntercomProvider>
      </QueryClientProvider>
    </SubscriptionDataProvider>
  </React.StrictMode>,
);
